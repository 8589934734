import type { UnwrapRef } from "vue"

export const useLoading = <F extends AnyFunc>(func: F) => {
  const isLoading = ref(false)

  const doRequest = (...args: any[]): ReturnType<F> => {
    isLoading.value = true

    return func(...args)
      .finally(() => {
        isLoading.value = false
      })
  }

  return {
    isLoading,
    doRequest
  }
}

export const useStatefulItem = <T extends SingaAPIObject>() => {
  const item = ref<Nullable<T>>()
  const errors = ref<SingaAPIErrors<T>>({})

  const setItem = (newItem: Nullable<T>) => {
    item.value = newItem
  }
  const setErrors = (newErrors: SingaAPIErrors<T>) => {
    errors.value = newErrors as UnwrapRef<typeof errors>
  }

  const reset = () => {
    item.value = null
    errors.value = {} as UnwrapRef<typeof errors>
  }

  return {
    item,
    errors,
    setItem,
    setErrors,
    reset
  }
}

export const useFormRef = () => {
  const form = ref<Nullable<HTMLFormElement>>(null)

  const isSubmittable = computed(() => !form.value?.$v?.$invalid)

  const validate = async (): Promise<boolean> =>
    form.value!.$v.$validate()

  const reset = () => {
    form.value!.$v.$reset()
  }

  return {
    form,
    isSubmittable,
    validate,
    reset
  }
}
